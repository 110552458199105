import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useLanguage } from '../context/LanguageContext';

interface WithNavigationProps {
  showNavigation?: boolean;
}

export const withWebView = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
  pageName: string
) => {
  return (props: P & WithNavigationProps) => {
    const { lang } = useParams();
    const { t } = useLanguage();
    const { showNavigation = true } = props;

    return (
      <div className={`${pageName}-page ${!showNavigation ? 'webview' : ''}`}>
        {showNavigation && (
          <header>
            <Link to={`/${lang}`} className="back-link">
              {t('common.backToHome')}
            </Link>
          </header>
        )}
        <WrappedComponent {...props} />
      </div>
    );
  };
}; 