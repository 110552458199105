import React from 'react';
import { useLanguage } from '../context/LanguageContext';
import EmailLink from './EmailLink';

const PrivacyContent: React.FC = () => {
  const { t } = useLanguage();

  const renderList = (items: string | string[]) => {
    if (!Array.isArray(items)) return null;
    return (
      <ul>
        {items.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    );
  };

  const formatContactText = (text: string | string[]) => {
    if (Array.isArray(text)) return text[0];
    const parts = text.split('privacy@colquest.com');
    return parts[0];
  };

  return (
    <main>
      <h1>{t('privacy.title')}</h1>

      <section>
        <h2>{t('privacy.intro.title')}</h2>
        <p>{t('privacy.intro.content')}</p>
      </section>

      <section>
        <h2>{t('privacy.collection.title')}</h2>
        <h3>{t('privacy.collection.personal.title')}</h3>
        <p>{t('privacy.collection.personal.intro')}</p>
        {renderList(t('privacy.collection.personal.items'))}

        <h3>{t('privacy.collection.usage.title')}</h3>
        <p>{t('privacy.collection.usage.intro')}</p>
        {renderList(t('privacy.collection.usage.items'))}
      </section>

      <section>
        <h2>{t('privacy.usage.title')}</h2>
        <p>{t('privacy.usage.intro')}</p>
        {renderList(t('privacy.usage.items'))}
      </section>

      <section>
        <h2>{t('privacy.security.title')}</h2>
        <p>{t('privacy.security.content')}</p>
      </section>

      <section>
        <h2>{t('privacy.contact.title')}</h2>
        <p>
          {formatContactText(t('privacy.contact.content'))}
          <EmailLink />
        </p>
      </section>

      <p className="last-updated">
        {t('privacy.lastUpdated')}
      </p>
    </main>
  );
};

export default PrivacyContent; 