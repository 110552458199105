import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useLanguage } from '../context/LanguageContext';
import { supabase } from '../lib/supabase';
import LanguageSwitcher from '../components/LanguageSwitcher';
import EmailLink from '../components/EmailLink';

// Feature flag for app store buttons
const SHOW_APP_STORE_BUTTONS = false;

interface WaitlistForm {
  email: string;
  platform: 'iOS' | 'Android';
  country: string;
  otherCountry?: string;
}

interface Screenshot {
  src: string;
  alt: string;
}

const screenshots = [
  { src: '/images/screenshots/home.png', alt: 'ColQuest home screen' },
  { src: '/images/screenshots/activity.png', alt: 'ColQuest activity tracking screen' },
  { src: '/images/screenshots/quests.png', alt: 'ColQuest quests screen' },
  { src: '/images/screenshots/main.png', alt: 'ColQuest main screen' },
];

const LandingPage: React.FC = () => {
  const { lang } = useParams();
  const { t, language, setLanguage } = useLanguage();
  
  const [activeIndex, setActiveIndex] = useState(0);
  const [isChanging, setIsChanging] = useState(false);
  const [autoRotate, setAutoRotate] = useState(true);
  const carouselRef = useRef<HTMLDivElement>(null);
  const [formData, setFormData] = useState<WaitlistForm>({
    email: '',
    platform: 'iOS',
    country: 'France',
    otherCountry: ''
  });
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const waitlistRef = useRef<HTMLElement>(null);

  // Update language context when URL language changes
  React.useEffect(() => {
    if (lang && (lang === 'en' || lang === 'fr')) {
      setLanguage(lang);
    }
  }, [lang, setLanguage]);

  const getTranslationString = (value: string | string[]): string => {
    return Array.isArray(value) ? value[0] : value;
  };

  const renderFeatureList = (features: string | string[]) => {
    if (!Array.isArray(features)) return null;
    return features.map((feature, index) => (
      <li key={index}>{feature}</li>
    ));
  };

  // Create an array with repeated screenshots for infinite scrolling
  const extendedScreenshots = [...screenshots, ...screenshots, ...screenshots];
  const offset = screenshots.length;
  const totalScreenshots = screenshots.length;

  // Function to handle smooth transitions
  const updateCarouselPosition = (index: number, immediate = false) => {
    if (!carouselRef.current) return;
    
    const itemWidth = 280; // Width of each screenshot
    const gap = 32; // Gap between items (2rem = 32px)
    const baseOffset = -(itemWidth + gap) * (index + offset);
    
    carouselRef.current.style.transition = immediate ? 'none' : 'transform 0.4s ease';
    carouselRef.current.style.transform = `translateX(${baseOffset}px)`;
  };

  // Handle the transition end to reset position for infinite scrolling
  const handleTransitionEnd = () => {
    if (!carouselRef.current) return;

    // If we've gone too far right
    if (activeIndex >= totalScreenshots) {
      setActiveIndex(0);
      updateCarouselPosition(0, true);
    }
    // If we've gone too far left
    else if (activeIndex < 0) {
      setActiveIndex(totalScreenshots - 1);
      updateCarouselPosition(totalScreenshots - 1, true);
    }

    setIsChanging(false);
  };

  // Auto-rotation effect
  useEffect(() => {
    if (!autoRotate) return;

    const interval = setInterval(() => {
      setIsChanging(true);
      setActiveIndex(current => (current + 1) % totalScreenshots);
    }, 3000);

    return () => clearInterval(interval);
  }, [autoRotate, totalScreenshots]);

  // Update carousel position when activeIndex changes
  useEffect(() => {
    updateCarouselPosition(activeIndex);
  }, [activeIndex]);

  const handleScreenshotClick = (index: number) => {
    if (isChanging) return;
    
    setAutoRotate(false);
    setIsChanging(true);
    setActiveIndex(index % totalScreenshots);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    
    try {
      const { error: supabaseError } = await supabase
        .from('waitlist')
        .insert([
          {
            email: formData.email,
            platform: formData.platform,
            country: formData.country === 'Other' ? formData.otherCountry : formData.country,
            source: 'website'
          }
        ]);

      if (supabaseError) {
        // If it's a unique constraint violation, treat it as success
        if (supabaseError.code === '23505') {
          setSubmitted(true);
          return;
        }
        
        console.error('Error submitting to waitlist:', supabaseError);
        setError(getTranslationString(t('landing.waitlist.error')));
        return;
      }

      setSubmitted(true);
    } catch (err) {
      console.error('Error submitting to waitlist:', err);
      setError(getTranslationString(t('landing.waitlist.error')));
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // List of supported/planned countries
  const countries = [
    'Belgium',
    'Denmark',
    'France',
    'Germany',
    'Italy',
    'Netherlands',
    'Spain',
    'Switzerland',
    'UK',
    'US',
    'Other'
  ];

  const scrollToWaitlist = () => {
    waitlistRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="landing-page">
      <header>
        <img src="/logos/triangle.svg" alt="ColQuest Logo" className="logo" />
        <h1>{t('landing.title')}</h1>
        <p>{t('landing.subtitle')}</p>
        <button onClick={scrollToWaitlist} className="cta-button">
          <span>{t('common.joinWaitlist.line1')}</span>
          <span>{t('common.joinWaitlist.line2')}</span>
        </button>
      </header>

      <main>
        <section className="hero">
          <div className="screenshots-container">
            <div className="carousel-container">
              {/* Mobile version */}
              <div className="mobile-carousel">
                <div className="phone-frame">
                  <img 
                    src={screenshots[activeIndex].src} 
                    alt={screenshots[activeIndex].alt} 
                    className="screenshot-img"
                    draggable="false"
                  />
                </div>
              </div>

              {/* Desktop version */}
              <div className="desktop-carousel">
                <div 
                  className="screenshots-carousel" 
                  ref={carouselRef}
                  onTransitionEnd={handleTransitionEnd}
                >
                  {extendedScreenshots.map((screenshot, index) => (
                    <div 
                      key={`${screenshot.src}-${index}`}
                      className={`screenshot ${(index - offset) % totalScreenshots === activeIndex ? 'active' : ''}`}
                      onClick={() => handleScreenshotClick(index - offset)}
                    >
                      <div className="phone-frame">
                        <img 
                          src={screenshot.src} 
                          alt={screenshot.alt} 
                          className="screenshot-img"
                          draggable="false"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="carousel-dots">
                {screenshots.map((_, index) => (
                  <div 
                    key={index}
                    className={`carousel-dot ${index === activeIndex ? 'active' : ''}`}
                    onClick={() => handleScreenshotClick(index)}
                  />
                ))}
              </div>
            </div>
          </div>
        </section>

        <section className="features">
          <h3>{t('landing.features.title')}</h3>
          <div className="feature-grid">
            <div className="feature">
              <h4>{t('landing.features.summitTracking.title')}</h4>
              <p>{t('landing.features.summitTracking.description')}</p>
            </div>
            <div className="feature">
              <h4>{t('landing.features.scoringClimbs.title')}</h4>
              <p>{t('landing.features.scoringClimbs.description')}</p>
            </div>
            <div className="feature">
              <h4>{t('landing.features.quests.title')}</h4>
              <p>{t('landing.features.quests.description')}</p>
            </div>
          </div>
        </section>

        <section className="roadmap">
          <h3>{t('landing.roadmap.title')}</h3>
          <div className="roadmap-grid">
            <div className="roadmap-column">
              <h4>{t('landing.roadmap.current.title')}</h4>
              <ul className="roadmap-list">
                {renderFeatureList(t('landing.roadmap.current.features'))}
              </ul>
            </div>
            <div className="roadmap-column">
              <h4>{t('landing.roadmap.future.title')}</h4>
              <ul className="roadmap-list">
                {renderFeatureList(t('landing.roadmap.future.features'))}
              </ul>
            </div>
          </div>
        </section>
        
        <section className="waitlist" ref={waitlistRef}>
          <h3>{t('landing.waitlist.title')}</h3>
          <p className="waitlist-description">{t('landing.waitlist.description')}</p>
          {submitted ? (
            <div className="success-message">
              <p>{t('landing.waitlist.success')}</p>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              {error && (
                <div className="error-message">
                  <p>{error}</p>
                </div>
              )}
              <div className="form-group">
                <label htmlFor="email">{t('landing.waitlist.emailLabel')}</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder={getTranslationString(t('landing.waitlist.placeholder'))}
                  required
                />
              </div>
              
              <div className="form-group">
                <label htmlFor="platform">{t('landing.waitlist.platformLabel')}</label>
                <select
                  id="platform"
                  name="platform"
                  value={formData.platform}
                  onChange={handleChange}
                  required
                >
                  <option value="iOS">iOS</option>
                  <option value="Android">Android</option>
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="country">{t('landing.waitlist.countryLabel')}</label>
                <select
                  id="country"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  required
                >
                  {countries.map(country => (
                    <option key={country} value={country}>{country}</option>
                  ))}
                </select>
                {formData.country === 'Other' && (
                  <input
                    type="text"
                    id="otherCountry"
                    name="otherCountry"
                    value={formData.otherCountry}
                    onChange={handleChange}
                    placeholder={getTranslationString(t('landing.waitlist.otherCountryPlaceholder'))}
                    required
                    className="other-country-input"
                  />
                )}
              </div>

              <button type="submit">{t('landing.waitlist.button')}</button>
            </form>
          )}
        </section>

        {SHOW_APP_STORE_BUTTONS && (
          <section className="app-stores">
            <h3>{t('landing.appStores.title')}</h3>
            <div className="store-buttons">
              <a href="#" className="app-store">{t('landing.appStores.appStore')}</a>
              <a href="#" className="play-store">{t('landing.appStores.playStore')}</a>
            </div>
          </section>
        )}
      </main>
    </div>
  );
};

export default LandingPage; 