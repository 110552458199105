import React from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../context/LanguageContext';
import LanguageSwitcher from './LanguageSwitcher';
import EmailLink from './EmailLink';
import { isWebView } from '../utils/webview';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { t, language } = useLanguage();
  const isWebViewMode = isWebView();
  const showLanguageSwitcher = !isWebViewMode;

  const formatYear = (template: string | string[], year: string) => {
    if (Array.isArray(template)) return template[0];
    return template.replace('{year}', year);
  };

  return (
    <div className="app">
      <nav>
        {showLanguageSwitcher && (
          <div className="nav-right">
            <LanguageSwitcher />
          </div>
        )}
      </nav>
      <main>
        {children}
      </main>
      {!isWebViewMode && (
        <footer>
          <div className="footer-links">
            <Link to={`/${language}/faq`}>FAQ</Link>
            <Link to={`/${language}/privacy`}>{t('common.privacyPolicy')}</Link>
            <EmailLink />
          </div>
          <p>{formatYear(t('common.copyright'), new Date().getFullYear().toString())}</p>
        </footer>
      )}
    </div>
  );
};

export default Layout; 