export const isWebView = () => {
  // Common WebView user agent patterns
  const patterns = [
    'Mobile/',
    'wv)',
    'Version/',
    'Android',
    'iPhone',
    'iPad',
    'ColQuestApp'  // Add your app's custom WebView identifier if you have one
  ];

  if (typeof window !== 'undefined') {
    const ua = window.navigator.userAgent;
    // Also check for a custom parameter we can add when opening in WebView
    const isWebViewParam = new URLSearchParams(window.location.search).get('webview') === 'true';
    return isWebViewParam || patterns.some(pattern => ua.includes(pattern));
  }
  return false;
}; 