import React, { createContext, useContext, useState, useEffect } from 'react';
import enTranslations from '../translations/en';
import frTranslations from '../translations/fr';

type Language = 'en' | 'fr';
type TranslationKey = string;

interface LanguageContextType {
  language: Language;
  setLanguage: (lang: Language) => void;
  t: (key: TranslationKey) => string | string[];
}

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

const getInitialLanguage = (): Language => {
  // Get language from URL
  const urlLang = window.location.pathname.split('/')[1];
  if (urlLang === 'fr') return 'fr';
  return 'en';
};

export const LanguageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [language, setLanguage] = useState<Language>(getInitialLanguage);

  const translations = {
    en: enTranslations,
    fr: frTranslations,
  };

  // Update URL when language changes
  useEffect(() => {
    const currentPath = window.location.pathname;
    const newPath = currentPath.replace(/^\/(en|fr)/, `/${language}`);
    if (currentPath !== newPath) {
      window.history.replaceState(null, '', newPath);
    }
  }, [language]);

  const t = (key: TranslationKey): string | string[] => {
    const translation = translations[language];
    return key.split('.').reduce((obj, key) => obj?.[key], translation) || key;
  };

  return (
    <LanguageContext.Provider value={{ language, setLanguage, t }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
}; 