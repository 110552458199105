export default {
  'landing.title': 'Bienvenue sur ColQuest',
  common: {
    backToHome: '← Retour à l\'accueil',
    copyright: '© {year} ColQuest. Tous droits réservés.',
    loading: 'Chargement...',
    price: 'Disponible dans les App Stores\nPrintemps 2025',
    joinWaitlist: {
      line1: '🏔️ Rejoignez l\'aventure 🚴‍♂️',
      line2: 'Lancement Printemps 2025'
    },
    privacyPolicy: 'Politique de Confidentialité',
    contact: 'Nous Contacter',
  },
  landing: {
    title: 'ColQuest',
    subtitle: 'Faisons grandir votre Col•lection',
    release: {
      coming: 'Disponible au Printemps 2025',
      date: 'Rejoignez la liste d\'attente pour un accès anticipé',
    },
    roadmap: {
      title: 'Feuille de route',
      current: {
        title: 'Au lancement',
        features: [
          'Applications iOS et Android',
          'Synchronisation avec Strava',
          'Suivi et découverte des cols',
          'Score et classement personnel',
          'Quêtes et défis régionaux',
        ] as string[],
      },
      future: {
        title: 'À Venir',
        features: [
          'Types de sommets additionnels',
          'Sommets non goudronnés',
          'Intégrations Garmin et autres plateformes',
          'Classements mondiaux et régionaux',
          'Fonctionnalités sociales et succès',
        ] as string[],
      }
    },
    waitlist: {
      title: 'Rejoignez la Liste d\'Attente',
      description: 'Soyez parmi les premiers à essayer ColQuest !\nEn rejoignant notre liste d\'attente, vous :\n• Obtiendrez un accès anticipé lors du lancement dans votre pays\n• Recevrez des mises à jour exclusives sur le développement',
      placeholder: 'Entrez votre email',
      emailLabel: 'Adresse Email',
      platformLabel: 'Plateforme Préférée',
      countryLabel: 'Votre Pays',
      otherCountryPlaceholder: 'Veuillez préciser votre pays',
      button: 'Rejoindre la Liste',
      success: 'Merci de votre inscription ! Nous vous contacterons dès que ColQuest sera disponible dans votre pays.',
      error: 'Désolé, une erreur s\'est produite lors de l\'inscription. Veuillez réessayer.',
    },
    appStores: {
      title: 'Télécharger Maintenant',
      appStore: 'Télécharger sur l\'App Store',
      playStore: 'Obtenir sur Google Play',
    },
    features: {
      title: 'Fonctionnalités',
      summitTracking: {
        title: 'Suivez Vos Cols',
        description: 'Détectez et enregistrez automatiquement chaque col et sommet conquis lors de vos sorties.',
      },
      scoringClimbs: {
        title: 'Marquez des Points',
        description: 'Gagnez des points selon la difficulté, le dénivelé et vos nouvelles découvertes',
      },
      quests: {
        title: 'Quêtes Épiques',
        description: 'Complétez des quêtes régionales pour gagner des points supplémentaires et donner un sens à vos sorties.',
      },
    },
  },
  faq: {
    title: 'Foire aux Questions',
    questions: {
      what: {
        question: 'Qu\'est-ce que ColQuest ?',
        answer: 'ColQuest est une application de cyclisme qui transforme vos ascensions en jeu passionnant. Elle analyse vos activités cyclistes depuis Strava, Garmin et d\'autres plateformes pour suivre les sommets que vous avez conquis, attribuant des points pour les nouvelles découvertes et les défis accomplis.',
      },
      summitSelection: {
        question: 'Comment sont sélectionnés les sommets ?',
        answer: 'Les sommets correspondent, pour le moment, exclusivement à des cols répertoriés sur Open Street Map. Nous comptons ajouter d\'autre sommets tels que le Mont Ventoux, la Planche des Belles filles etc.\nN\'hésitez pas à nous suggérer des sommets via notre email !',
      },
      summitDetection: {
        question: 'Comment sont identifiés les sommets sur une activités ?',
        answer: 'Nous comparons la trace GPS de votre activité aux coordonnées des sommets de la base ColQuest. Si votre trace est à moins de 200 mètres d\'un sommet ce sommet est considéré comme franchi.',
      },
      scoring: {
        question: 'Comment est défini le score pour un sommet ?',
        answer: 'Nous identifions la route empruntée pour l\'ascension, calculons sa pente et sa longueur. Ces deux paramètres donnent un score initial. Il est ensuite bonifié selon la rareté du sommet et si c\'est la première fois que vous le franchissez.',
      },
      import: {
        question: 'Est-ce que je peux importer mes activités?',
        answer: 'Nous supportons l\'import automatique d\'activités Strava. Votre historique est chargé au moment de l\'inscription. Nous comptons supporter d\'autres plateforme, tel Garmin. Nous souhaitons aussi permettre l\'import manuel de fichiers GPX directement dans l\'application.',
      },
      pricing: {
        question: 'Est-ce que ColQuest est payant?',
        answer: 'L\'inscription est gratuite.\nL\'accès à votre score global, votre classement et les statistiques de vos futures activités est payant, pour 10€ par an.',
      },
      when: {
        question: 'Quand l\'application sera-t-elle disponible ?',
        answer: 'Nous sommes actuellement en développement et lancerons bientôt. Inscrivez-vous à la liste d\'attente pour être informé du lancement !',
      },
      price: {
        question: 'ColQuest est-il gratuit ?',
        answer: 'ColQuest proposera des fonctionnalités gratuites et premium. Les fonctionnalités de base seront gratuites, avec des fonctionnalités avancées disponibles via un abonnement premium.',
      },
      matching: {
        question: 'Comment fonctionne la correspondance universitaire ?',
        answer: 'Notre algorithme de correspondance intelligent prend en compte votre profil académique, vos intérêts, vos préférences et vos objectifs pour suggérer les universités qui correspondent le mieux à vos critères.',
      },
      tracking: {
        question: 'Puis-je suivre plusieurs candidatures universitaires ?',
        answer: 'Oui ! ColQuest vous permet de suivre plusieurs candidatures universitaires, leurs délais et leurs exigences en un seul endroit.',
      },
    },
  },
  privacy: {
    title: 'Politique de Confidentialité',
    intro: {
      title: 'Introduction',
      content: 'Chez ColQuest, nous prenons votre confidentialité au sérieux. Cette politique de confidentialité explique comment nous collectons, utilisons, divulguons et protégeons vos informations lorsque vous utilisez notre application mobile.',
    },
    collection: {
      title: 'Informations que Nous Collectons',
      personal: {
        title: 'Informations Personnelles',
        intro: 'Nous collectons les informations que vous nous fournissez directement, notamment :',
        items: [
          'Nom et coordonnées',
          'Informations académiques',
          'Préférences universitaires',
          'Informations sur le statut des candidatures',
        ],
      },
      usage: {
        title: 'Informations d\'Utilisation',
        intro: 'Nous collectons automatiquement certaines informations sur votre appareil et votre interaction avec notre application :',
        items: [
          'Informations sur l\'appareil',
          'Informations de connexion',
          'Schémas d\'utilisation',
        ],
      },
    },
    usage: {
      title: 'Comment Nous Utilisons Vos Informations',
      intro: 'Nous utilisons les informations collectées pour :',
      items: [
        'Fournir et améliorer nos services',
        'Personnaliser votre expérience',
        'Communiquer avec vous',
        'Analyser les schémas d\'utilisation',
        'Protéger contre la fraude et les abus',
      ],
    },
    security: {
      title: 'Sécurité des Données',
      content: 'Nous mettons en œuvre des mesures techniques et organisationnelles appropriées pour protéger vos informations personnelles contre l\'accès, la modification, la divulgation ou la destruction non autorisés.',
    },
    contact: {
      title: 'Nous Contacter',
      content: 'Si vous avez des questions sur cette politique de confidentialité, veuillez nous contacter à : ',
    },
    lastUpdated: 'Dernière mise à jour : Mars 2025',
  },
}; 