import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLanguage } from '../context/LanguageContext';

const LanguageSwitcher: React.FC = () => {
  const { language, setLanguage } = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLanguageChange = (newLang: 'en' | 'fr') => {
    // Update the language in context
    setLanguage(newLang);
    
    // Update the URL to reflect the new language
    const newPath = location.pathname.replace(/^\/(en|fr)/, `/${newLang}`);
    navigate(newPath);
  };

  return (
    <div className="language-switcher">
      <button
        onClick={() => handleLanguageChange('en')}
        className={`language-button ${language === 'en' ? 'active' : ''}`}
      >
        EN
      </button>
      <button
        onClick={() => handleLanguageChange('fr')}
        className={`language-button ${language === 'fr' ? 'active' : ''}`}
      >
        FR
      </button>
    </div>
  );
};

export default LanguageSwitcher; 