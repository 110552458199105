import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/Layout';
import LandingPage from './pages/LandingPage';
import FaqPage from './pages/FaqPage';
import PrivacyPage from './pages/PrivacyPage';
import WebViewPrivacyPage from './pages/WebViewPrivacyPage';
import WebViewFaqPage from './pages/WebViewFaqPage';
import { LanguageProvider } from './context/LanguageContext';
import { isWebView } from './utils/webview';

const App: React.FC = () => {
  const showNavigation = !isWebView();

  return (
    <BrowserRouter>
      <LanguageProvider>
        <Layout>
          <Routes>
            {/* Redirect root to /en */}
            <Route path="/" element={<Navigate to="/en" replace />} />
            
            {/* Language-specific routes */}
            <Route path="/:lang" element={<LandingPage />} />
            <Route 
              path="/:lang/faq" 
              element={<FaqPage showNavigation={showNavigation} />} 
            />
            <Route 
              path="/:lang/privacy" 
              element={<PrivacyPage showNavigation={showNavigation} />} 
            />
            
            {/* Catch all route - redirect to /en */}
            <Route path="*" element={<Navigate to="/en" replace />} />
          </Routes>
        </Layout>
      </LanguageProvider>
    </BrowserRouter>
  );
};

export default App; 