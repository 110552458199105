import React from 'react';
import { useLanguage } from '../context/LanguageContext';
import EmailLink from './EmailLink';

const FaqContent: React.FC = () => {
  const { t } = useLanguage();

  // Get all FAQ questions from translations
  const faqQuestions = [
    'what',
    'when',
    'summitSelection',
    'summitDetection',
    'scoring',
    'import',
    'pricing',
  ];

  const faqs = faqQuestions.map(key => ({
    question: t(`faq.questions.${key}.question`),
    answer: t(`faq.questions.${key}.answer`),
  }));

  return (
    <main>
      <h1>{t('faq.title')}</h1>
      <div className="faq-grid">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <h3>{faq.question}</h3>
            <p>{faq.answer}</p>
          </div>
        ))}
      </div>
    </main>
  );
};

export default FaqContent; 