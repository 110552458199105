export default {
  common: {
    backToHome: '← Back to Home',
    copyright: '© {year} ColQuest. All rights reserved.',
    loading: 'Loading...',
    price: 'Coming to App Stores\nSpring 2025',
    joinWaitlist: {
      line1: '🏔️ Join the Adventure 🚴‍♂️',
      line2: 'Launching Spring 2025'
    },
    privacyPolicy: 'Privacy Policy',
    contact: 'Contact Us',
  },
  landing: {
    title: 'ColQuest',
    subtitle: 'Start your Col•lection',
    release: {
      coming: 'Coming Spring 2025',
      date: 'Join the waitlist to get early access',
    },
    hero: {
      title: 'Discover and Conquer New Summits',
      description: 'Join cyclists tracking their mountain conquests and completing exciting challenges',
    },
    roadmap: {
      title: 'Roadmap',
      current: {
        title: 'At Launch',
        features: [
          'iOS and Android apps',
          'Strava ride synchronization',
          'Col tracking and discovery',
          'Personal score and ranking',
          'Regional quests and challenges',
        ] as string[],
      },
      future: {
        title: 'Coming Next',
        features: [
          'Additional summit types beyond Cols',
          'Offroad summits',
          'Garmin and other platform integrations',
          'Global and regional leaderboards',
          'Social features and achievements',
        ] as string[],
      }
    },
    waitlist: {
      title: 'Join the Waitlist',
      description: 'Be among the first to try ColQuest!\nBy joining our waitlist, you\'ll:\n• Get early access when we launch in your country\n• Receive exclusive updates about development',
      placeholder: 'Enter your email',
      emailLabel: 'Email Address',
      platformLabel: 'Preferred Platform',
      countryLabel: 'Your Country',
      otherCountryPlaceholder: 'Please specify your country',
      button: 'Join Waitlist',
      success: 'Thanks for joining! We\'ll notify you when ColQuest is available in your country.',
      error: 'Sorry, there was an error joining the waitlist. Please try again.',
    },
    appStores: {
      title: 'Start Your Journey',
      appStore: 'Download on App Store',
      playStore: 'Coming Soon on Google Play',
    },
    features: {
      title: 'Features',
      summitTracking: {
        title: 'Track Your Summits',
        description: 'Automatically detect and log every col and summit you conquer during your rides.',
      },
      scoringClimbs: {
        title: 'Score Your Climbs',
        description: 'Earn points based on climb difficulty, elevation gain, and new discoveries.',
      },
      quests: {
        title: 'Epic Quests',
        description: 'Complete regional quests to earn extra points and give purpose to your rides.',
      },
    },
  },
  faq: {
    title: 'Frequently Asked Questions',
    questions: {
      what: {
        question: 'What is ColQuest?',
        answer: 'ColQuest is a cycling app that turns your mountain climbs into an exciting game. It analyzes your cycling activities from Strava, Garmin, and other platforms to track the summits you\'ve conquered, awarding points for new discoveries and completed challenges.',
      },
      summitSelection: {
        question: 'How are summits selected?',
        answer: 'Currently, summits exclusively correspond to mountain passes (cols) listed on Open Street Map. We plan to add other summits such as Mont Ventoux, La Planche des Belles Filles, etc.\nFeel free to suggest summits to us by contacting us via email!',
      },
      summitDetection: {
        question: 'How are summits identified in an activity?',
        answer: 'We compare your activity\'s GPS track with the coordinates of summits in the ColQuest database. If your track comes within 200 meters of a summit, that summit is considered conquered.',
      },
      scoring: {
        question: 'How is the score for a summit determined?',
        answer: 'We identify the road taken for the ascent and calculate its gradient and length. These two parameters provide an initial score. This is then enhanced based on the summit\'s rarity and whether it\'s your first time conquering it.',
      },
      import: {
        question: 'Can I import my activities?',
        answer: 'We support automatic import of Strava activities. Your history is loaded when you sign up. We plan to support other platforms, such as Garmin. We also want to enable manual import of GPX files directly into the application.',
      },
      pricing: {
        question: 'Is ColQuest paid?',
        answer: 'Registration is free.\nAccess to your global score, ranking, and statistics for your future activities is paid, at €10 per year.',
      },
      when: {
        question: 'When will the application be available?',
        answer: 'We are currently developing and will soon launch. Join our waitlist to be notified when it launches!',
      },
      price: {
        question: 'Which regions are supported?',
        answer: 'Currently, ColQuest tracks summits in France. We\'re actively working on expanding to other countries to bring the excitement of summit hunting worldwide.',
      },
      matching: {
        question: 'How does the point system work?',
        answer: 'You earn points in multiple ways: discovering new summits, conquering challenging climbs, and completing quests (themed groups of summits). The more difficult the climb or quest, the more points you earn!',
      }
    },
  },
  privacy: {
    title: 'Privacy Policy',
    intro: {
      title: 'Introduction',
      content: 'At ColQuest, we take your privacy seriously. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our cycling application.',
    },
    collection: {
      title: 'Information We Collect',
      personal: {
        title: 'Personal Information',
        intro: 'We collect information that you provide directly to us, including:',
        items: [
          'Name and contact information',
          'Connected activity platforms (Strava, Garmin, etc.)',
          'Activity and route data',
          'Achievement history',
        ],
      },
      usage: {
        title: 'Usage Information',
        intro: 'We automatically collect certain information about your device and activities:',
        items: [
          'Device information',
          'Activity tracking data',
          'Summit completion records',
        ],
      },
    },
    usage: {
      title: 'How We Use Your Information',
      intro: 'We use the information we collect to:',
      items: [
        'Track your summit achievements',
        'Calculate points and rankings',
        'Provide quest recommendations',
        'Improve summit detection',
        'Enhance the gaming experience',
      ],
    },
    security: {
      title: 'Data Security',
      content: 'We implement appropriate technical and organizational measures to protect your personal information and activity data against unauthorized access, alteration, disclosure, or destruction.',
    },
    contact: {
      title: 'Contact Us',
      content: 'If you have any questions about this Privacy Policy, please contact us at: ',
    },
    lastUpdated: 'Last updated: March 2025',
  },
}; 