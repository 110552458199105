import React, { useState, useEffect } from 'react';
import { useLanguage } from '../context/LanguageContext';

const EmailLink: React.FC<{ className?: string }> = ({ className }) => {
  const [email, setEmail] = useState('');
  const [showEmail, setShowEmail] = useState(false);
  const { t } = useLanguage();

  useEffect(() => {
    // Further obfuscate the email parts with simple encoding
    const encode = (str: string) => str.split('').map(char => String.fromCharCode(char.charCodeAt(0) + 1)).join('');
    const decode = (str: string) => str.split('').map(char => String.fromCharCode(char.charCodeAt(0) - 1)).join('');
    
    // Encoded parts
    const encodedUser = 'dpoubdu';  // encoded 'contact'
    const encodedDomain = 'dpmrvftu/dd'; // encoded 'colquest.cc'
    
    // Decode at runtime
    const user = decode(encodedUser);
    const domain = decode(encodedDomain);
    
    setEmail(`${user}@${domain}`);
  }, []);

  const handleClick = (e: React.MouseEvent) => {
    setShowEmail(true);
  };

  return (
    <a
      href={`mailto:${email}`}
      onClick={handleClick}
      className={className}
      rel="nofollow"
      aria-label="Contact email"
      data-email="protected"
    >
      {showEmail ? email : t('common.contact')}
    </a>
  );
};

export default EmailLink; 